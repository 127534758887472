import React, {useState} from "react"
import { useStaticQuery, graphql } from "gatsby"

import TheaterVideo from "@components/Theater/TheaterVideo"
import CloseIcon from "../CloseIcon"

import { Dialog, setHotkeysDialogProps } from "@blueprintjs/core"

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer";

var classNames = require("classnames")

export default function Modal(props) {
    var modalClass = classNames({
        "c-menu": true,
        "dialog-one": true,
    })

    const data = useStaticQuery(graphql`
    query anesQuery {
        allUniquePagesJson(filter: {title: {eq: "anesthesia-options/"}}) {
          nodes {
            anesthesiaTypes {
              blurb
              buttonOne {
                buttonLabel
                modalBlurb
                modalHeading
                youtube
              }
              buttonThree {
                buttonLabel
                modalBlurb
                modalHeading
                youtube
              }
              heading
              buttonTwo {
                buttonLabel
                modalBlurb
                modalHeading
                youtube
              }
            }
          }
        }
      }
    `)

    const [dialogOpen, setDialogOpen] = useState(false);
    const [activeText, setActiveText] =  useState({});

    function toggleDialog(activeText) {
        setDialogOpen(!dialogOpen)
        setActiveText(activeText)
    }

    let anesthesiaTypes = data.allUniquePagesJson.nodes[0].anesthesiaTypes;

    return (
     <>
            <Dialog
            canOutsideClickClose={true}
            enforceFocus={true}
            autoFocus={true}
            canEscapeKeyClose={true}
            isOpen={dialogOpen}
            usePortal={true}
            onClose={toggleDialog}
            className="anesthesia-dialog"
        >
            <div className="has-text-centered dialog-content">
                <CloseIcon onClick={toggleDialog} />
                <h3>{activeText.modalHeading}</h3>
                <p className="large">
                    {activeText.modalBlurb}
                </p>
                {activeText.youtube && <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${activeText.youtube}`}
                    controls={true}
                    playing={true}
                    onlyButton={true}
                    buttonClass="contained"
                />}
                {!activeText.youtube && <div style={{ height: "80px" }}></div>}
            </div>
        </Dialog>
            <div
            className="columns has-text-centered color-back anesthesia-modal"
            style={{ paddingTop: "40px", paddingBottom: "40px" }}
          >
            <div className="column is-5"></div>
            <div className="column">
                  <h3>{anesthesiaTypes.heading}</h3>
              <MarkdownViewer markdown={anesthesiaTypes.blurb} />
              <div className="button-group-wrapper">
                <div className="button-group is-centered has-3-button">
                <div className="has-text-centered">
                <a className="standard-button contained" onClick={() => toggleDialog({ modalHeading: anesthesiaTypes.buttonOne.modalHeading, modalBlurb: anesthesiaTypes.buttonOne.modalBlurb, youtube: anesthesiaTypes.buttonOne.youtube })}>{anesthesiaTypes.buttonOne.buttonLabel}</a>
                <a className="standard-button contained" onClick={() => toggleDialog({ modalHeading: anesthesiaTypes.buttonTwo.modalHeading, modalBlurb: anesthesiaTypes.buttonTwo.modalBlurb, youtube: anesthesiaTypes.buttonTwo.youtube })}>{anesthesiaTypes.buttonTwo.buttonLabel}</a>
                <a className="standard-button contained" onClick={() => toggleDialog({ modalHeading: anesthesiaTypes.buttonThree.modalHeading, modalBlurb: anesthesiaTypes.buttonThree.modalBlurb, youtube: anesthesiaTypes.buttonThree.youtube })}>{anesthesiaTypes.buttonThree.buttonLabel}</a>
            </div>
                </div>
              </div>
            </div>
            <div className="column is-5"></div>
          </div>
     </>
    )
}
